import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AuthComponent} from './auth/auth.component'
import {AuthenticationGuard} from './auth/authentication.guard'

const routes: Routes = [
    {path: 'auth', component: AuthComponent, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
    {path: 'app', loadChildren: () => import('./protected/protected.module').then(m => m.ProtectedModule), canActivate: [AuthenticationGuard]},
    {path: 'thank-you', loadChildren: () => import('./thank-you-page-quickbooks/thank-you-page.module').then(m => m.ThankYouPageModule)},
    {path: 'helps', loadChildren: () => import('./connect-provider/connect-provider.module').then(m => m.ConnectProviderModule)},
    {path: 'help-center',component: AuthComponent, loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule)},
    {path: 'integrations', component: AuthComponent, loadChildren: () => import('./integrations/integrations.routes')},
    {path: 'thanks', component: AuthComponent, loadChildren: () => import('./thank-you/thank-you.routes')},
    {path: 'terms-conditions', component: AuthComponent, loadChildren: () => import('./terms-conditions/terms-conditions.routes')},
    {path: '', redirectTo: 'app', pathMatch: 'full'},
    {path: '**', redirectTo: 'app'},
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
