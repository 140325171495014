
import {EnvTypes} from './env.types'

export const environment: EnvTypes = {
  production: false,
  version: '0.97',
  API_URL: 'https://attentive-proposal-tool-back-end-dev.onrender.com/api/v1/',
  BUILD_TIME: '2024-09-29T12:18:16.671Z',
  GOOGLE_DRIVE_PROPOSAL_FOLDER_ID: 'undefined',
  GOOGLE_DRIVE_RESULT_FOLDER_ID: 'undefined',
  DECISION_RULES_APP_URL: 'undefined',
  versions: {
    app: '0.97',
    buildTime: '2024-09-29T12:18:16.671Z'
  }
}
